import { Module } from 'vuex';
import { db } from '@/firebase';
import {
  serverTimestamp,
  collection,
  addDoc,
  doc,
  getDoc,
  updateDoc,
  query,
  orderBy,
  limit,
  getDocs,
  deleteDoc,
  startAfter
} from 'firebase/firestore';

// eslint-disable-next-line
const notesModule: Module<any, any> = {
  namespaced: true,

  state: {
    notes: [],
    lastNote: null
  },
  mutations: {
    setNotes(state, notes) {
      state.notes = notes;
    },
    addNote(state, newNote) {
      state.notes.unshift(newNote);
    },
    setLastNote(state, note) {
      state.lastNote = note;
    }
  },
  actions: {
    async addNote(context) {
      const famId = context.rootGetters['user/getUser'].familyId;
      const newNote = {
        createdByUid: context.rootGetters['auth/getAuthUser'].uid,
        createdByName:
          context.rootGetters['user/getUser'].nickname ||
          context.rootGetters['user/getUser'].firstName,
        createdAt: serverTimestamp(),
        modifiedByName:
          context.rootGetters['user/getUser'].nickname ||
          context.rootGetters['user/getUser'].firstName,
        modifiedByUid: context.rootGetters['auth/getAuthUser'].uid,
        modifiedAt: serverTimestamp()
      };
      try {
        const colRef = collection(db, 'families', famId, 'notes');
        const newDocRef = await addDoc(colRef, newNote);
        const createdNote = {
          ...newNote,
          id: newDocRef.id
        };
        context.commit('addNote', createdNote);
        return newDocRef;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async getNote(context, noteId) {
      const famId = context.rootGetters['user/getUser'].familyId;
      const docRef = doc(db, 'families', famId, 'notes', noteId);
      try {
        const res = await getDoc(docRef);
        return { ...res.data(), id: res.id };
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async getNotes(context) {
      const famId = context.rootGetters['user/getUser'].familyId;
      const q = query(
        collection(db, 'families', famId, 'notes'),
        orderBy('modifiedAt', 'desc'),
        limit(10)
      );
      try {
        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const loadedNotes: any[] = [];
          snapshot.forEach((n) => {
            loadedNotes.push({ ...n.data(), id: n.id });
          });
          context.commit('setNotes', loadedNotes);
          context.commit(
            'setLastNote',
            snapshot.docs[snapshot.docs.length - 1]
          );
          return snapshot;
        } else {
          context.commit('setNotes', []);
          return snapshot;
        }
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async getMoreNotes(context) {
      const famId = context.rootGetters['user/getUser'].familyId;
      const q = query(
        collection(db, 'families', famId, 'notes'),
        orderBy('modifiedAt', 'desc'),
        startAfter(context.getters['lastNote']),
        limit(10)
      );
      try {
        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const moreNotes: any[] = [];
          snapshot.forEach((note) => {
            moreNotes.push({ ...note.data(), id: note.id });
          });
          const loadedNotes = [...context.getters['notes']].concat(moreNotes);
          context.commit('setNotes', [...loadedNotes]);
          const lastVisible = snapshot.docs[snapshot.docs.length - 1];
          context.commit('setLastNote', lastVisible);
          return snapshot;
        } else {
          console.log('no more entries');
          return snapshot;
        }
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async updateNote(context, note) {
      const famId = context.rootGetters['user/getUser'].familyId;
      const docRef = doc(db, 'families', famId, 'notes', note.id);
      try {
        return updateDoc(docRef, {
          ...note,
          modifiedByName:
            context.rootGetters['user/getUser'].nickname ||
            context.rootGetters['user/getUser'].firstName,
          modifiedByUid: context.rootGetters['auth/getAuthUser'].uid,
          modifiedAt: serverTimestamp()
        });
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async deleteNote(context, id) {
      const famId = context.rootGetters['user/getUser'].familyId;
      const docRef = doc(db, 'families', famId, 'notes', id);
      try {
        return await deleteDoc(docRef);
      } catch (error) {
        console.log(error);
        return error;
      }
    }
  },
  getters: {
    notes(state) {
      return state.notes;
    },
    lastNote(state) {
      return state.lastNote;
    }
  }
};

export default notesModule;

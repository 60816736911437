import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/store/modules/auth';
import journal from '@/store/modules/journal';
import user from '@/store/modules/user';
import family from '@/store/modules/family';
import notes from '@/store/modules/notes';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    journal,
    user,
    family,
    notes
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {}
});


import { Component, Prop, Vue } from 'vue-property-decorator';
import { Entry, Family } from '@/models';

@Component({})
export default class SinceLastCard extends Vue {
  @Prop() private entry!: Entry;
  private getDate: Date | null = null;
  private time = '';
  private days = 0;
  private hours = 0;
  private minutes = 0;
  private seconds = 0;
  private get displayTime() {
    const time = this.time.split(':');
    const hours = Number(time[0]);
    const minutes = Number(time[1]) < 10 ? '0' + Number(time[1]) : time[1];
    let timeValue = '';
    if (hours > 0 && hours <= 12) {
      timeValue = '' + hours;
    } else if (hours > 12) {
      timeValue = '' + (hours - 12);
    } else if (hours == 0) {
      timeValue = '12';
    }
    timeValue += ':' + minutes;
    timeValue += hours >= 12 ? ' pm' : ' am';
    return timeValue;
  }
  private get timestampDisplay() {
    return (
      new Date(this.entry.entryTimestamp.seconds * 1000)
        .toString()
        .substr(0, 10)
        .toLowerCase() +
      ' at ' +
      this.displayTime
    );
  }
  private get elapsedTimeDisplay() {
    if (this.days) {
      return this.days + ' days ' + this.hours + ' hours ago';
    } else if (this.hours) {
      return this.hours + ' hours ' + this.minutes + ' minutes ago';
    } else {
      return this.minutes + ' minutes ago';
    }
  }

  private get journalEntryLink() {
    return this.$route.path + '/' + this.entry.id;
  }

  private get familyData() {
    return this.$store.getters['family/getFamily'] as Family;
  }

  // return color bassed on entry name
  private depColor(name: string) {
    const color = this.familyData.dependents.find(
      (dep) => dep.nickname === name
    )?.color;
    return color;
  }

  mounted(): void {
    this.setDiffs();
    this.setTimestamp();
  }

  private setDiffs() {
    this.seconds = Math.abs(
      (new Date().valueOf() - this.entry.entryTimestamp.seconds * 1000) / 1000
    );
    this.days = Math.floor(this.seconds / 60 / 60 / 24);
    this.hours = Math.floor((this.seconds / 60 / 60) % 24);
    this.minutes = Math.floor((this.seconds / 60) % 60);
    this.seconds = Math.floor(this.seconds % 60);
  }

  private setTimestamp() {
    this.getDate = new Date(this.entry.entryTimestamp.seconds * 1000);
    this.time = this.getDate.getHours() + ':' + this.getDate.getMinutes();
  }
}


import { Component, Vue } from 'vue-property-decorator';
import NavDrawer from './NavDrawer.vue';
import JournalSettings from '@/components/settings/JournalConfig.vue';
import HomeConfig from '@/components/settings/HomeConfig.vue';

@Component({
  components: {
    NavDrawer,
    JournalSettings,
    HomeConfig
  }
})
export default class TheHeader extends Vue {
  private drawer = false;
  private isConfigDialogShowing = false;

  private get isSignIn() {
    return this.$route.name === 'SignIn';
  }

  private get headerText() {
    if (this.$route.path.startsWith('/journal')) {
      return 'Journal';
    } else if (this.$route.path.startsWith('/calendar')) {
      return 'Calendar';
    } else if (this.$route.path.startsWith('/notes')) {
      return 'Notes';
    } else if (this.$route.path.startsWith('/settings')) {
      return 'Settings';
    } else if (this.$route.name === 'Home') {
      return 'Home';
    } else {
      return '';
    }
  }

  private get showConfigButton() {
    return this.$route.name === 'Journal' || this.$route.name === 'Home';
  }

  private get authUser() {
    return this.$store.getters['auth/getAuthUser'];
  }

  private get userInfo() {
    return this.$store.getters['user/getUser'];
  }

  private get userClaims() {
    return this.$store.getters['auth/getUserClaims'];
  }

  private goHome() {
    this.$router.push('/');
  }
  private signOut() {
    this.$store.dispatch('auth/signOut');
  }

  private showConfigDialog() {
    this.isConfigDialogShowing = true;
  }

  private handleSavedHomeConfig() {
    this.isConfigDialogShowing = false
  }
}


import { Component, Vue } from 'vue-property-decorator';
import { httpsCallable } from '@firebase/functions';
import { functions, auth } from '@/firebase';
import { Family, User } from '@/models';
import SinceLastContainer from '@/components/home/SinceLastContainer.vue';
import CalendarContainer from '@/components/home/CalendarContainer.vue';
import Profile from '@/components/settings/Profile.vue';

@Component({
  components: {
    SinceLastContainer,
    CalendarContainer,
    Profile
  }
})
export default class Home extends Vue {
  private inviteLoading = false;
  private get userInfo() {
    return this.$store.getters['auth/getAuthUser'];
  }
  private get user() {
    return this.$store.getters['user/getUser'] as User;
  }
  private get familyData() {
    return this.$store.getters['family/getFamily'] as Family;
  }
  private get authStateChangeIsLoading() {
    return this.$store.getters['auth/getIsLoading'] as boolean;
  }

  private signOut() {
    this.$store.dispatch('auth/signOut');
  }

  private async declineInvite(inviteId: string) {
    console.log('start decline');
    this.inviteLoading = true;
    const decline = httpsCallable(functions, 'declineFamilyInvite');
    await decline({ userInviteId: inviteId });
    this.inviteLoading = false;
    window.location.reload();
    console.log('complete decline');
  }

  private async acceptInvite(inviteId: string) {
    console.log('start accept');
    this.inviteLoading = true;
    const accept = httpsCallable(functions, 'acceptFamilyInvite');
    await accept({ userInviteId: inviteId });
    await auth.currentUser?.getIdToken(true);
    this.inviteLoading = false;
    window.location.reload();
    console.log('complete accept');
  }

  mounted(): void {
    setTimeout(() => {
      if (!this.user) {
        window.location.reload();
      }
    }, 5000);
  }
}


import { Component, Vue, Watch } from 'vue-property-decorator';
import { JournalSettings, Family } from '@/models';

@Component({})
export default class HomeConfig extends Vue {
  private isSaving = false;
  private selection: string[] = [];

  private get familyData() {
    return this.$store.getters['family/getFamily'] as Family;
  }
  private get journalSettings(): JournalSettings {
    return this.$store.getters['family/getJournalSettings'];
  }
  private get homeSettings(): string[] {
    return this.$store.getters['family/getHomeSettings'];
  }

  @Watch('homeSettings', { immediate: true })
  onHomeSettingsChange(newSettings: string[]): void {
    if (newSettings && newSettings.length) {
      this.selection = [ ...newSettings ];
    } else this.selection = [];
  }

  private get hasChanged() {
    return JSON.stringify(this.homeSettings) != JSON.stringify(this.selection)
  }

  private async saveHomeSettings() {
    this.isSaving = true;
    await this.$store.dispatch('family/updateHomeSettings', [
      ...this.selection
    ]);
    this.$emit('saved-home-config')
    this.isSaving = false;
  }
}

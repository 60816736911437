
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ConfirmationModal extends Vue {
  @Prop({ required: true, type: Boolean }) 
  private isVisible!: boolean;
  @Prop() private titleText!: string;
  @Prop() private bodyText!: string;
  @Prop() private trueButtonText!: string;
  @Prop() private falseButtonText!: string;
  @Prop({ required: false, default: false, type: Boolean })
  private trueButtonLoading!: boolean;
  @Prop({ required: false, default: false, type: Boolean })
  private userInputRequired!: boolean;

  private inputData = '';

  private emitConfirmation(): void {
    this.$emit('userResponse', { response: true, input: this.inputData });
    this.inputData = '';
  }
  private emitCancel(): void {
    this.$emit('userResponse', { response: false });
    this.inputData = '';
  }
}

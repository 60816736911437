
import Vue from 'vue';
import TheHeader from '@/components/layout/TheHeader.vue';
import TheFooter from '@/components/layout/TheFooter.vue';
import { Unsubscribe } from 'firebase/auth';

export default Vue.extend({
  name: 'App',
  components: { TheHeader, TheFooter },

  data: () => ({
    refreshing: false,
    registration: {} as ServiceWorkerRegistration,
    updateExists: false,
    unsubscribe: {} as Unsubscribe
  }),

  methods: {
    async authChange() {
      this.unsubscribe = await this.$store.dispatch('auth/authChange');
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateAvailable(event: any) {
      this.registration = event.detail;
      this.updateExists = true;
    },
    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  },

  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, {
      once: true
    });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },

  mounted() {
    this.authChange();
  },
  destroyed() {
    this.unsubscribe();
  }
});

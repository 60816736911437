
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Entry, Family } from '@/models';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query
} from '@firebase/firestore';
import { where } from 'firebase/firestore';
import { db } from '@/firebase';
import SinceLastCard from '@/components/home/SinceLastCard.vue';

@Component({
  components: {
    SinceLastCard
  }
})
export default class SinceLastContainer extends Vue {
  private lastEntries: Entry[] = [];
  private isLoading = false;

  private get familyData() {
    return this.$store.getters['family/getFamily'] as Family;
  }

  private get homeSettings(): string[] {
    return this.$store.getters['family/getHomeSettings'];
  }

  mounted(): void {
    this.getLastEntries();
  }

  @Watch('homeSettings')
  private async getLastEntries() {
    this.isLoading = true;
    const entries: Entry[] = [];
    const famId = this.familyData.familyId;
    if (this.homeSettings && this.homeSettings.length) {
      for (const t of this.homeSettings) {
        const q = query(
          collection(db, 'families', famId, 'journal'),
          where('type', '==', t),
          orderBy('entryTimestamp', 'desc'),
          limit(1)
        );
        try {
          const snapshot = await getDocs(q);
          if (!snapshot.empty) {
            snapshot.forEach((entry) => {
              entries.push({ ...entry.data(), id: entry.id } as Entry);
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
      entries.sort(
        (a, b) => b.entryTimestamp.seconds - a.entryTimestamp.seconds
      );
      this.lastEntries = entries;
    }
    this.isLoading = false;
  }
}

import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import { auth } from '@/firebase';
import { onAuthStateChanged, Unsubscribe } from 'firebase/auth';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/journal',
    name: 'Journal',
    component: () =>
      import(/* webpackChunkName: "journal" */ '../views/journal/Journal.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/journal/newentry',
    name: 'NewJournalEntry',
    component: () =>
      import(
        /* webpackChunkName: "newentry" */ '../views/journal/NewJournalEntry.vue'
      ),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/journal/:id',
    name: 'JournalEntry',
    component: () =>
      import(
        /* webpackChunkName: "journalentry" */ '../views/journal/JournalEntry.vue'
      ),
    meta: {
      authRequired: true
    },
    props: true
  },
  {
    path: '/journal/:id/edit',
    name: 'EditJournalEntry',
    component: () =>
      import(
        /* webpackChunkName: "editjournalentry" */ '../views/journal/EditJournalEntry.vue'
      ),
    meta: {
      authRequired: true
    },
    props: true
  },
  {
    path: '/notes',
    name: 'Notes',
    component: () =>
      import(/* webpackChunkName: "notes" */ '../views/notes/Notes.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/notes/:id',
    name: 'EditNote',
    component: () =>
      import(/* webpackChunkName: "editnotes" */ '../views/notes/EditNote.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () =>
      import(/* webpackChunkName: "calendar" */ '../views/Calendar.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () =>
      import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () =>
      import(/* webpackChunkName: "signin" */ '../views/SignIn.vue')
  },
  {
    path: '/registrationsuccess',
    name: 'RegistrationSuccess',
    component: () =>
      import(/* webpackChunkName: "registrationsuccess" */ '../views/RegistrationSuccess.vue')
  },
  {
    path: '/:notFound(.*)',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue'),
    meta: {
      authRequired: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let unsubscribe: Unsubscribe;
  if (to.meta?.authRequired) {
    unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      if (user) {
        next();
      } else {
        console.log('Please Sign In');
        next({
          path: '/signin'
        });
      }
    });
  } else if (to.name === 'SignIn') {
    unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      if (user) {
        next({
          path: '/'
        });
      } else {
        next();
      }
    });
  } else {
    next();
  }
});

export default router;

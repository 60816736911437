
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class NavDrawer extends Vue {
  private items = [
    {
      icon: 'mdi-home',
      text: 'Home',
      route: '/'
    },
    {
      icon: 'mdi-baby-bottle',
      text: 'Journal',
      route: '/journal'
    },
    {
      icon: 'mdi-calendar',
      text: 'Calendar',
      route: '/calendar'
    },
    {
      icon: 'mdi-text',
      text: 'Notes',
      route: '/notes'
    },
    {
      icon: 'mdi-cog',
      text: 'Settings',
      route: '/settings'
    }
  ];
}

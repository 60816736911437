
import { Component, Vue, Watch } from 'vue-property-decorator';
import { JournalSettings, Family } from '@/models';

@Component({})
export default class JournalConfig extends Vue {
  private settings = {} as JournalSettings;
  private selectedJournal = '';
  private showAddTypeDialog = false;
  private hasChanged = false;
  private isSaving = false;
  private isDraggingIndex = -2;
  private isDraggingJournal = '';
  private isDraggingOverIndex = -2;

  private get familyData() {
    return this.$store.getters['family/getFamily'] as Family;
  }
  private get journalSettings(): JournalSettings {
    return this.$store.getters['family/getJournalSettings'];
  }

  @Watch('journalSettings', { immediate: true })
  onJournalSettingsChange(newSettings: JournalSettings): void {
    this.settings = { ...newSettings };
    for (const journal in this.settings) {
      this.settings[journal] = { ...newSettings[journal] };
      this.settings[journal].types = [...newSettings[journal].types];
    }
  }

  private deleteType(journalName: string, index: number) {
    this.settings[journalName].types.splice(index, 1);
    this.hasChanged = true;
  }

  private addTypeHandler(journalName: string) {
    this.selectedJournal = journalName;
    this.showAddTypeDialog = true;
  }
  private addTypeDialogResponseHandler({
    response,
    input
  }: {
    response: boolean;
    input: string;
  }) {
    if (response) {
      this.settings[this.selectedJournal].types.push(input);
    }
    this.hasChanged = true;
    this.selectedJournal = '';
    this.showAddTypeDialog = false;
  }
  private async saveJournalSettings() {
    this.isSaving = true;
    await this.$store.dispatch('family/updateJournalSettings', {
      ...this.settings
    });
    this.$emit('saved-journal-settings')
    this.hasChanged = false;
    this.isSaving = false;
  }

  private dragStartHandler(journalName: string, index: number) {
    this.isDraggingIndex = index;
    this.isDraggingJournal = journalName;
  }
  private dragEndHandler() {
    this.isDraggingIndex = -2;
    this.isDraggingOverIndex = -2;
    this.isDraggingJournal = '';
  }
  private dragOverHandler(index: number) {
    const noDrop = index + 1;
    if (this.isDraggingOverIndex != index) {
      if (this.isDraggingIndex == index || this.isDraggingIndex == noDrop)
        return;
      this.isDraggingOverIndex = index;
    }
  }
  private dragLeaveHandler() {
    this.isDraggingOverIndex = -2;
  }
  private dropHandler(journalName: string, index: number) {
    const noDrop = index + 1;
    if (this.isDraggingIndex == index || this.isDraggingIndex == noDrop) return;
    const typeMoving = this.settings[journalName].types.splice(
      this.isDraggingIndex,
      1
    );
    this.settings[journalName].types.splice(
      this.isDraggingIndex <= index ? index : index + 1,
      0,
      typeMoving[0]
    );
    this.hasChanged = true;
  }
  private moveUp(journalName: string, index: number) {
    if (index == 0) return;
    const typeMoving = this.settings[journalName].types.splice(index, 1);
    this.settings[journalName].types.splice(index - 1, 0, typeMoving[0]);
    this.hasChanged = true;
  }
  private movedown(journalName: string, index: number) {
    if (index == this.settings[journalName].types.length - 1) return;
    const typeMoving = this.settings[journalName].types.splice(index, 1);
    this.settings[journalName].types.splice(index + 1, 0, typeMoving[0]);
    this.hasChanged = true;
  }
}


import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/models';
import { User as AuthUserModel } from 'firebase/auth';

@Component({})
export default class Profile extends Vue {
  @Prop({ required: false, default: false, type: Boolean })
  private inHomeComponent!: boolean;

  @Watch('userData')
  onUserDataChange(newUser: User): void {
    this.user = { ...newUser };
    this.isLoading = false;
  }

  private isLoading = false;
  private user = {} as User;
  private inEditMode = false;

  private get authUserInfo() {
    return this.$store.getters['auth/getAuthUser'] as AuthUserModel;
  }

  private get userData() {
    return this.$store.getters['user/getUser'];
  }

  private async saveUserData() {
    this.isLoading = true;
    await this.$store.dispatch('user/updateUser', {
      uid: this.authUserInfo.uid,
      user: this.user
    });
    this.inEditMode = false;
  }

  private exitEditMode() {
    this.inEditMode = false;
    this.user = { ...this.userData };
  }

  private async linkWithGoogle() {
    try {
      await this.$store.dispatch('auth/linkWithGoogle');
    } catch (err) {
      console.log('Log In error');
    }
  }

  created(): void {
    this.onUserDataChange(this.userData);
  }

  mounted(): void {
    if (this.inHomeComponent) {
      this.inEditMode = true;
    }
  }
}

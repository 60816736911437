
import { Component, Vue } from 'vue-property-decorator';
import { User } from '@/models';

@Component({})
export default class TheFooter extends Vue {
  private items = [
    {
      icon: 'mdi-home',
      text: 'Home',
      route: '/'
    },
    {
      icon: 'mdi-baby-bottle',
      text: 'Journal',
      route: '/journal'
    },
    {
      icon: 'mdi-calendar',
      text: 'Calendar',
      route: '/calendar'
    },
    {
      icon: 'mdi-text',
      text: 'Notes',
      route: '/notes'
    }
  ];

  private get authUser() {
    return this.$store.getters['auth/getAuthUser'];
  }

  private get user() {
    return this.$store.getters['user/getUser'] as User;
  }

  private get settingsDisplay() {
    if (this.$route.query.tab === 'family') {
      return { icon: 'mdi-home-heart', text: 'Family' };
    } else if (this.$route.query.tab === 'app') {
      return { icon: 'mdi-application-cog', text: 'App' };
    } else {
      return { icon: 'mdi-account', text: 'Profile' };
    }
  }

  private get showFooter() {
    const routeName = this.$route.name;
    if (
      routeName != 'NewJournalEntry' &&
      routeName != 'EditJournalEntry' &&
      routeName != 'EditNote'
    ) {
      return true;
    }
    return false;
  }
}

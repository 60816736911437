import { Module } from 'vuex';
import { Entry } from '@/models';
import { db, storage } from '@/firebase';
import {
  collection,
  doc,
  addDoc,
  serverTimestamp,
  query,
  getDocs,
  orderBy,
  limit,
  where,
  startAfter,
  updateDoc,
  deleteDoc,
  Query
} from 'firebase/firestore';
import { ref, uploadBytesResumable, deleteObject } from 'firebase/storage';

// eslint-disable-next-line
const journalModule: Module<any, any> = {
  namespaced: true,

  state: {
    entries: [],
    lastEntry: null
  },
  mutations: {
    setJournal(state, entries) {
      state.entries = entries;
    },
    setLastEntry(state, entry) {
      state.lastEntry = entry;
    },
    deleteJournalEntry(state, entryId) {
      const entryIndex = state.entries.findIndex(
        (entry: Entry) => entry.id === entryId
      );
      state.entries.splice(entryIndex, 1);
    },
    addJournalEntry(state, newEntry) {
      state.entries.push(newEntry);
    },
    editJournalEntry(state, updatedEntry) {
      const entryIndex = state.entries.findIndex(
        (entry: Entry) => entry.id === updatedEntry.id
      );
      state.entries.splice(entryIndex, 1, updatedEntry);
    }
  },
  actions: {
    async queryJournal(context, journalQuery) {
      context.commit('setJournal', []);
      let q: Query;
      if (journalQuery.type === 'all' && journalQuery.name === 'all') {
        q = query(
          collection(
            db,
            'families',
            context.rootGetters['user/getUser'].familyId,
            'journal'
          ),
          orderBy('entryTimestamp', 'desc'),
          limit(10)
        );
      } else {
        const conditions = [];
        if (journalQuery.type != 'all') conditions.push(where('type', '==', journalQuery.type));
        if (journalQuery.name != 'all') conditions.push(where('name', '==', journalQuery.name));
        q = query(
          collection(
            db,
            'families',
            context.rootGetters['user/getUser'].familyId,
            'journal'
          ),
          ...conditions,
          orderBy('entryTimestamp', 'desc'),
          limit(10)
        );
      }
      try {
        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
          // eslint-disable-next-line
          const loadedJournal: any[] = [];
          snapshot.forEach((entry) => {
            loadedJournal.push({ ...entry.data(), id: entry.id });
          });
          context.commit('setJournal', [...loadedJournal]);
          const lastVisible = snapshot.docs[snapshot.docs.length - 1];
          context.commit('setLastEntry', lastVisible);
          return snapshot;
        } else {
          context.commit('setJournal', []);
          return snapshot;
        }
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async queryForMore(context, selectedType) {
      let q: Query;
      if (selectedType === 'all') {
        q = query(
          collection(
            db,
            'families',
            context.rootGetters['user/getUser'].familyId,
            'journal'
          ),
          orderBy('entryTimestamp', 'desc'),
          startAfter(context.getters['lastEntry']),
          limit(10)
        );
      } else {
        q = query(
          collection(
            db,
            'families',
            context.rootGetters['user/getUser'].familyId,
            'journal'
          ),
          where('type', '==', selectedType),
          orderBy('entryTimestamp', 'desc'),
          startAfter(context.getters['lastEntry']),
          limit(10)
        );
      }
      try {
        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
          // eslint-disable-next-line
          const moreJournal: any[] = [];
          snapshot.forEach((entry) => {
            moreJournal.push({ ...entry.data(), id: entry.id });
          });
          const loadedJournal = [...context.getters['journal']].concat(
            moreJournal
          );
          context.commit('setJournal', [...loadedJournal]);
          const lastVisible = snapshot.docs[snapshot.docs.length - 1];
          context.commit('setLastEntry', lastVisible);
          return snapshot;
        } else {
          console.log('no more entries');
          return snapshot;
        }
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async addJournalEntry(context, entryData) {
      const famId = context.rootGetters['user/getUser'].familyId;
      let newDocRef;
      const newEntry = {
        ...entryData.entry,
        createdByUid: context.rootGetters['auth/getAuthUser'].uid,
        createdByName:
          context.rootGetters['user/getUser'].nickname ||
          context.rootGetters['user/getUser'].firstName,
        createdAt: serverTimestamp()
      };
      try {
        const colRef = collection(db, 'families', famId, 'journal');
        if (entryData.image) {
          newDocRef = await addDoc(colRef, newEntry);
        } else {
          return await addDoc(colRef, newEntry);
        }
      } catch (error) {
        console.log(error);
        return error;
      }
      try {
        const imageRef = ref(
          storage,
          `${famId}/${newDocRef.id}/${entryData.image.name}`
        );
        await uploadBytesResumable(imageRef, entryData.image);
        const extIndex = entryData.image.name.lastIndexOf('.');
        const ext = entryData.image.name.substring(extIndex);
        const imageName = `${entryData.image.name.substring(
          0,
          extIndex
        )}_600x600${ext}`;
        return updateDoc(newDocRef, {
          imageUrl: 'pending',
          imageName: imageName
        });
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async editJournalEntry(context, updatedEntry) {
      const famId = context.rootGetters['user/getUser'].familyId;
      const docRef = doc(
        db,
        'families',
        famId,
        'journal',
        updatedEntry.entry.id
      );
      if (updatedEntry.deleteImage) {
        try {
          await this.dispatch('journal/deleteImage', updatedEntry.deleteImage);
        } catch (error) {
          console.log(error);
          return error;
        }
      }
      try {
        if (updatedEntry.image) {
          await updateDoc(docRef, { ...updatedEntry.entry });
        } else {
          return updateDoc(docRef, { ...updatedEntry.entry });
        }
      } catch (error) {
        console.log(error);
        return error;
      }
      try {
        const imageRef = ref(
          storage,
          `${famId}/${docRef.id}/${updatedEntry.image.name}`
        );
        await uploadBytesResumable(imageRef, updatedEntry.image);
        const extIndex = updatedEntry.image.name.lastIndexOf('.');
        const ext = updatedEntry.image.name.substring(extIndex);
        const imageName = `${updatedEntry.image.name.substring(
          0,
          extIndex
        )}_600x600${ext}`;
        return updateDoc(docRef, { imageUrl: 'pending', imageName: imageName });
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async deleteJournalEntry(context, data) {
      if (data.imageUrl) {
        try {
          await this.dispatch('journal/deleteImage', data.imageUrl);
        } catch (error) {
          console.log(error);
          return error;
        }
      }
      try {
        const docRef = doc(
          db,
          'families',
          context.rootGetters['user/getUser'].familyId,
          'journal',
          data.id
        );
        return await deleteDoc(docRef);
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async deleteImage(context, imageUrl) {
      try {
        const imageRef = ref(storage, imageUrl);
        return deleteObject(imageRef);
      } catch (error) {
        console.log(error);
        return error;
      }
    }
  },
  getters: {
    journal(state) {
      return state.entries;
    },
    lastEntry(state) {
      return state.lastEntry;
    }
  }
};

export default journalModule;

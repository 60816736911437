import { Module } from 'vuex';
import { db } from '@/firebase';
import {
  getDoc,
  doc,
  updateDoc,
  query,
  collection,
  orderBy,
  limit,
  getDocs
} from 'firebase/firestore';

// eslint-disable-next-line
const userModule: Module<any, any> = {
  namespaced: true,

  state: {
    user: null
  },
  mutations: {
    setUser(state, userData) {
      state.user = userData;
    }
  },
  actions: {
    async loadUser(context, uid) {
      const docRef = doc(db, 'users', uid);
      try {
        const user = await getDoc(docRef);
        if (user.exists()) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const loadedInvites: any[] = [];
          if (!user.data().familyId) {
            const q = query(
              collection(db, 'users', uid, 'invites'),
              orderBy('createdTimestamp', 'desc'),
              limit(3)
            );
            const snapshot = await getDocs(q);
            if (!snapshot.empty) {
              snapshot.forEach((invite) => {
                loadedInvites.push({ ...invite.data(), id: invite.id });
              });
            }
          }
          context.commit('setUser', { ...user.data(), invites: loadedInvites });
          if (user.data().familyId && user.data().familyId != null) {
            await this.dispatch('family/loadFamily', user.data().familyId);
          }
        } else {
          console.log('User doc does not exist.');
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateUser(context, userData) {
      const docRef = doc(db, 'users', userData.uid);
      try {
        await updateDoc(docRef, userData.user);
        context.commit('setUser', userData.user);
      } catch (error) {
        console.log(error);
      }
    }
  },
  getters: {
    getUser(state) {
      return state.user;
    }
  }
};

export default userModule;

import { Module } from 'vuex';
import router from '@/router';
import { auth, analytics, db } from '@/firebase';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  onAuthStateChanged,
  signOut,
  AuthError,
  ActionCodeSettings,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithRedirect,
  linkWithRedirect
} from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import { doc, setDoc } from '@firebase/firestore';

// eslint-disable-next-line
const authModule: Module<any, any> = {
  namespaced: true,

  state: {
    authUser: null,
    authError: null,
    userClaims: null,
    isLoading: true
  },
  mutations: {
    setAuthUser(state, payload) {
      state.authUser = payload;
    },
    setAuthError(state, payload) {
      state.authError = payload;
    },
    setUserClaims(state, payload) {
      state.userClaims = payload;
    },
    setIsLoading(state, payload) {
      state.isLoading = payload;
    }
  },
  actions: {
    async signIn(context, data) {
      try {
        const userCredentials = await signInWithEmailAndPassword(
          auth,
          data.email,
          data.password
        );
        const user = userCredentials.user;
        context.commit('setAuthError', {
          errorCode: null,
          errorMessage: null
        });
        logEvent(analytics, 'login', { method: 'Email and Password' });
        if (!user.emailVerified) {
          await signOut(auth);
          await sendEmailVerification(user);
          router.replace('/registrationsuccess');
        } else {
          router.replace('/');
        }
      } catch (error) {
        const authError = error as AuthError;
        context.commit('setAuthError', {
          code: authError.code,
          message: authError.message
        });
      }
    },
    async signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      return signInWithRedirect(auth, provider);
    },
    async linkWithGoogle() {
      const provider = new GoogleAuthProvider();
      if (auth.currentUser) {
        return linkWithRedirect(auth.currentUser, provider);
      } else return null;
    },
    async signOut() {
      try {
        await signOut(auth);
        router.replace('/signin');
      } catch (error) {
        const authError = error as AuthError;
        alert(authError.message);
      }
    },
    async register(context, userData) {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          userData.email,
          userData.password
        );
        const user = userCredential.user;
        await updateProfile(user, { displayName: userData.firstName });
        if (!user.emailVerified) {
          await sendEmailVerification(user);
        }
        await setDoc(doc(db, 'users', user.uid), {
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName
        });
        context.commit('setAuthError', {
          errorCode: null,
          errorMessage: null
        });
        logEvent(analytics, 'sign_up', { method: 'Email and Password' });
        if (!user.emailVerified) {
          await signOut(auth);
          router.replace('/registrationsuccess');
        } else {
          router.replace('/');
        }
      } catch (error) {
        const authError = error as AuthError;
        context.commit('setAuthError', {
          code: authError.code,
          message: authError.message
        });
      }
    },
    async passwordReset(context, email) {
      try {
        const codeSettings: ActionCodeSettings = {
          url: 'https://mi-familia-alpha.web.app/'
        };
        await sendPasswordResetEmail(auth, email, codeSettings);
      } catch (error) {
        // do nothing
      }
    },
    async authChange(context) {
      return onAuthStateChanged(auth, async (user) => {
        context.commit('setIsLoading', true);
        if (user && user.emailVerified) {
          context.commit('setAuthUser', user);
          const claims = await user.getIdTokenResult();
          context.commit('setUserClaims', {
            familyId: claims.claims.familyId,
            familyRole: claims.claims.familyRole
          });
          await this.dispatch('user/loadUser', user.uid);
        } else {
          context.commit('setAuthUser', null);
          context.commit('setUserClaims', null);
        }
        context.commit('setIsLoading', false);
      });
    },
    resetAuthError(context) {
      context.commit('setAuthError', {
        errorCode: null,
        errorMessage: null
      });
    }
  },
  getters: {
    authError(state) {
      return state.authError;
    },
    getAuthUser(state) {
      return state.authUser;
    },
    getUserClaims(state) {
      return state.userClaims;
    },
    getIsLoading(state) {
      return state.isLoading;
    }
  }
};

export default authModule;

import { initializeApp, FirebaseOptions } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyDkwEWKOjlihlzd4L5LkfMBjSuSOAn1d3M',
  authDomain: 'mi-familia-alpha.firebaseapp.com',
  projectId: 'mi-familia-alpha',
  storageBucket: 'mi-familia-alpha.appspot.com',
  messagingSenderId: '932904676052',
  appId: '1:932904676052:web:2e16674c4cfbb8e276a941',
  measurementId: 'G-MX4DSGWZEE'
};
const firebaseApp = initializeApp(firebaseConfig);

// Disable App Check when doing local dev
if (location.hostname != 'localhost') {
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(
      '6LeHiXkdAAAAAJErmF5hjhVGJyCmV3nwSLcErCT1'
    ),
    isTokenAutoRefreshEnabled: true
  });
}

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp);
const storage = getStorage(firebaseApp);
const analytics = getAnalytics();
// Use the Firebase emulator if local.
// Disable this if wanting to test with production Firebase
if (location.hostname === 'localhost') {
  connectFirestoreEmulator(db, 'localhost', 9090);
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(storage, 'localhost', 9199);
}
export { db, auth, functions, storage, analytics };

import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import ConfirmationModal from '@/components/util/ConfirmationModal.vue';
import BaseVCard from '@/components/util/BaseVCard.vue';

Vue.config.productionTip = false;
Vue.component('ConfirmationModal', ConfirmationModal);
Vue.component('BaseVCard', BaseVCard);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
